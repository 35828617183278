// components
import { Button, Table, TableColumn, Input } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import searchInput from '@/components/searchInput';
import schoolDialog from './components/schoolDialog';
// api
import { getRegisterSchool, getRegisterSchoolAdd, getRegisterSchoolEdit } from '@/api/back-stage';

import { formatTime } from '@/utils/common';

export default {
  name: 'registerSchool',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    backStageHeader,
    qlPagination,
    searchInput,
    schoolDialog,
  },
  data() {
    return {
      loading: false,
      schoolName: '',
      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      visible: false,
      isEdit: false,
      form: {
        id: '',
        schoolKey: '',
        schoolName: '',
        schoolType: '',
        architectId: '',
        educationKey: '',
        areaCode: '',
        areaPath: '',
        areaName: '',
        validateTime: '',
      },

      popupData: {
        schoolTypeList: [],
        educationList: [],
        architectList: [],
        isInit: false,
      },
      // 配置
      serverConfigVisible: false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 加载表格数据
    getDataList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getRegisterSchool({
        pageIndex,
        pageSize,
        schoolNameFuzzy: this.schoolName,
      })
        .then((res) => {
          if (res.result) {
            let { data, recordCount } = res.result;
            this.listData.list = data;
            this.listData.total = recordCount;
          } else {
            this.listData.list = [];
            this.listData.total = 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 初始化弹窗数据
    getPopupData() {
      return new Promise((resolve, reject) => {
        if (this.popupData.isInit) {
          resolve();
        } else {
          getRegisterSchoolAdd().then(
            (res) => {
              const { architectList, educationList, schoolTypeList } = res.result;
              this.popupData.schoolTypeList = schoolTypeList;
              this.popupData.architectList = architectList;
              this.popupData.educationList = educationList;
              this.popupData.isInit = true;
              resolve();
            },
            (err) => {
              reject(err);
            }
          );
        }
      });
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 新增学校
    async handleAddSchool() {
      await this.getPopupData();
      this.form = {
        id: null,
        schoolKey: '',
        schoolName: '',
        schoolType: this.popupData.schoolTypeList[0].typeId,
        architectId: this.popupData.architectList[0].id,
        educationKey: this.popupData.educationList[0].educationKey,
        areaCode: '',
        areaPath: '',
        areaName: '',
        validateTime: '',
        defaultCode: [],
      };
      this.isEdit = false;
      this.visible = true;
    },
    // 编辑
    async handleEdit(item) {
      await this.getPopupData();
      const res = await getRegisterSchoolEdit({
        id: item.id,
      });
      const schoolData = res.result.school;
      const defaultCode = [item.countryCode, item.provinceCode, item.cityCode, item.areaCode];

      this.form = {
        id: item.id,
        schoolKey: item.schoolKey,
        schoolName: item.schoolName,
        schoolType: item.schoolType,
        architectId: item.architectId,
        educationKey: item.serverName,
        areaCode: schoolData.areaCode,
        areaPath: schoolData.areaPath,
        areaName: schoolData.areaName,
        validateTime: formatTime(schoolData.validateTime.time || ''),
        defaultCode,
      };
      this.isEdit = true;
      this.visible = true;
    },

    // 保存成功
    handleSuccess() {
      this.visible = false;
      this.getDataList();
    },

    handleSearch() {
      this.getDataList(true);
    },
  },
};
